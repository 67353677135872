<div [ngSwitch]="nameApp">
    <!-- <div *ngSwitchCase="enumApp.GAN_PREV">
        <div class="row" *ngIf="isPromotionDisplay">
            <div class="col-md-10" *ngIf="isConjoint">
                <span class="remise">Profitez d'une réduction de 10% sur le 2<span
                        style="text-transform: lowercase;"><sup>ème</sup></span> contrat souscrit par votre
                    conjoint</span>
            </div>
            <div class="col-md-10" *ngIf="!isConjoint">
                <span class="remise"> Profitez d'une réduction de 5 % sur vos 2 contrats avec l'offre Foyer (2 contrats
                    souscrits simultanément).</span>
            </div>
            <div>
                <button mat-flat-button type="button" class="button-select"
                    [color]="checkValue===true ? 'primary' : 'basic'" [ngClass]="{'pristine': checkValue}"
                    (click)="selectPromotion(true)">Oui</button>
                <button mat-flat-button type="button" class="button-select"
                    [color]="checkValue===false ? 'primary' : 'basic'" style="margin-left: 16px;"
                    [ngClass]="{'pristine': checkValue==false}" (click)="selectPromotion(false)">Non</button>
            </div>

        </div>
    </div> -->

    <div *ngSwitchCase="enumApp.GAN_PREV">
            <div class="BG-information" *ngIf="isPromotionDisplay">
    
                <label class="offre"><img src="../../../assets/img/duo.svg"> Offre fidélité</label>
                <div *ngIf="isConjoint">
                    <span class="remise">Profitez d'une <strong>réduction de 10% sur le 2<span
                                style="text-transform: lowercase;"><sup>ème</sup></span> contrat souscrit</strong> par votre
                        conjoint</span>
                </div>
                <div *ngIf="!isConjoint">
                        <span class="remise">Profitez d'une <strong>réduction de 5% sur vos 2 contrats avec l'offre Foyer </strong>(2 contrats
                            souscrits simultanément).</span>
                </div>
                <br />
                Souhaitez-vous ajouter un(e) assuré(e) ?
                <br>
    
                <div class="row" style="margin-top: 16px">
                    <div class="col-md-6" style="display: flex;">
                        <button mat-flat-button type="button" class="button-select pristine"
                            [color]="checkValue===true ? 'primary' : 'basic'" (click)="selectPromotion(true)">Oui</button>
                        <button mat-flat-button type="button" class="button-select pristine"
                            [color]="checkValue===false ? 'primary' : 'basic'" style="margin-left: 16px;"
                            (click)="selectPromotion(false)">Non</button>
                    </div>
                </div>
            </div>
        </div>

    <div *ngSwitchCase="enumApp.GROUPAMA">
        <div class="BG-information" *ngIf="isPromotionDisplay">

            <label class="offre"><img src="../../../assets/img/duo.svg"> Offre fidélité</label>
            <span class="remise">Profitez d'une <strong>réduction de 10% sur le 2<span
                        style="text-transform: lowercase;"><sup>ème</sup></span> contrat souscrit</strong> par votre
                conjoint</span>
            <br />
            Souhaitez-vous ajouter un(e) assuré(e) ?
            <br>

            <div class="row" style="margin-top: 16px">
                <div class="col-md-6" style="display: flex;">
                    <button mat-flat-button type="button" class="button-select pristine"
                        [color]="checkValue===true ? 'primary' : 'basic'" (click)="selectPromotion(true)">Oui</button>
                    <button mat-flat-button type="button" class="button-select pristine"
                        [color]="checkValue===false ? 'primary' : 'basic'" style="margin-left: 16px;"
                        (click)="selectPromotion(false)">Non</button>
                </div>
            </div>
        </div>
    </div>

    <div *ngSwitchCase="enumApp.GAN_PAT">
        <div class="BG-information" *ngIf="isPromotionDisplay">

            <label class="offre"><img src="../../../assets/img/duo.svg"> Offre fidélité</label>
            <span class="remise">Profitez d'une <strong>réduction de 10% sur le 2<span
                        style="text-transform: lowercase;"><sup>ème</sup></span> contrat souscrit</strong> par votre
                conjoint</span>
            <br />
            Souhaitez-vous ajouter un(e) assuré(e) ?
            <br>

            <div class="row" style="margin-top: 16px">
                <div class="col-md-6" style="display: flex;">
                    <button mat-flat-button type="button" class="button-select pristine"
                        [color]="checkValue===true ? 'primary' : 'basic'" (click)="selectPromotion(true)">Oui</button>
                    <button mat-flat-button type="button" class="button-select pristine"
                        [color]="checkValue===false ? 'primary' : 'basic'" style="margin-left: 16px;"
                        (click)="selectPromotion(false)">Non</button>
                </div>
            </div>
        </div>
    </div>
</div>