<div class="main-div dimension" *ngIf="urlFrom ==  'Groupama' || affaire.mailCommercial !== null">
  
  <mat-horizontal-stepper [linear]="isLinear" #stepper class="stepper" labelPosition="bottom" iconOverrides="false">

    <mat-step [stepControl]="assureFormGroup" [completed]="assureFormGroup.valid" state="step1">
      <ng-template matStepLabel>Vous</ng-template>
      <br>
      <label class="titleLabel">Vos informations</label>

      <div class="info">
        <div class="definition">
          <img class="picto" src="../../../assets/img/ampoule.svg">
          <label class="astuceDef" *ngIf="appName === enumApp.GROUPAMA">Pourquoi Groupama vous demande ces informations</label><br>
          <label class="astuceDef" *ngIf="appName !== enumApp.GROUPAMA">Pourquoi Gan Patrimoine vous demande ces informations</label><br>
          <span>Ces informations sont nécessaires au traitement de votre devis. </span><br>
          <span>Vos données personnelles ne seront pas communiquées à d’autres organismes à des fins commerciales. </span><br><br>
         
        </div>

      </div>
      <br>
      
      <formulaire [formG]="assureFormGroup" [type]="'assure'" [isControl]="isControl"  [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}"></formulaire>

      <div class="row" [formGroup]="assureFormGroup" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="field" >
            <mat-label>Situation familiale</mat-label>
            <mat-select formControlName="situationFamiliale" (selectionChange)="changeSituationFamiliale()" required [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
              <mat-option *ngFor="let situation of situationFamiliales" [value]="situation.code" >
                {{ situation.libelle }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12" *ngIf="isPromotionDisplay">
          <div class="speech speech--red tail--up tail--left">
            <app-promotion [isConjoint]="affaire.hasConjoint" [isPromotionDisplay]="isPromotionDisplay"
            [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}" (onClick)="updateRemise($event)">
            </app-promotion>
          </div>
        </div>
      </div>
      <br>

      <div>
        <button mat-button type="submit" color="accent" matStepperNext (click)="save(1)"
          class="orange1">SUIVANT</button>
      </div>

    </mat-step>

    <mat-step [stepControl]="secondAssureFormGroup" *ngIf="affaire.isTwoAssure"
      [completed]="secondAssureFormGroup.valid" state="step2">
      <ng-template matStepLabel>Deuxième assuré(e)</ng-template>
      <br>
      <div *ngIf="affaire.isTwoAssure">
        <div *ngIf="affaire.hasConjoint">
          <label class="titleLabel">Vos informations</label>
        </div>
        <div class="col-sm-12 col-sm-offset-1" *ngIf="!affaire.hasConjoint">
          <h2>2<span style="text-transform: lowercase;"><sup>ème</sup></span>
            assuré</h2>
        </div>
      </div>

      <formulaire [formG]="secondAssureFormGroup" [type]="'secondAssure'" [isControl]="isSecondControl"
        *ngIf="affaire.isTwoAssure" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}" ></formulaire>

      <br>
      <br>
      <div>
        <button mat-button matStepperPrevious class="nav-retour">
          <img src="../../assets/img/left.svg">
          Etape précédente
        </button>

        <button mat-button matStepperNext (click)="save(2)" class="orange1">SUIVANT
        </button>
      </div>

    </mat-step>

    <mat-step state="step3">
      <ng-template matStepLabel>Votre devis</ng-template>
      <br>

      <div [ngSwitch]="appName">

        <div *ngSwitchCase="enumApp.GROUPAMA">

          <app-cotisation [formG]="assureFormGroup" [type]="'assure'" (recalc)="recalc($event)"></app-cotisation>

          <div class="info">
            <div class="definition">
              <img class="picto" src="../../../assets/img/ampoule.svg">
              <label class="astuce">Définitions</label><br>
              <span><strong>La cotisation viagère</strong> (durée à vie) : Vous réglez des cotisations tout au long de
                votre vie. </span><br>
              <span>Le + : <strong>vos cotisations sont moins élevées</strong> qu'en cotisations temporaires. </span>
              <br>
              <br>
              <span><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                déterminée (entre 10 et 20 ans). </span><br>
            </div>

          </div>

          <div [formGroup]="assureFormGroup">
            <div class="row" formGroupName="tarif">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="field">
                  <mat-label>Périodicité de paiement (*)</mat-label>
                  <mat-select formControlName="periodicite" (selectionChange)="save()">
                    <mat-option *ngFor="let periodicite of periodicites" [value]="periodicite.code">
                      {{ periodicite.libelle }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="affaire.isTwoAssure">
            <div class="col-sm-12 col-sm-offset-1" *ngIf="affaire.hasConjoint">
              <h2>Votre conjoint</h2>
            </div>
            <div class="col-sm-12 col-sm-offset-1" *ngIf="!affaire.hasConjoint">
              <h2>2<span style="text-transform: lowercase;"><sup>ème</sup></span>
                assuré</h2>
            </div>
            <br>
          </div>

          <app-cotisation [formG]="secondAssureFormGroup" [type]="'secondAssure'" (recalc)="recalc($event)"
            *ngIf="affaire.isTwoAssure">
          </app-cotisation>

        </div>

        <div *ngSwitchCase="enumApp.GAN_PAT">

          <div class="row">
            <div class="col-md-6">
              <h2>Vous</h2>
              <div [formGroup]="assureFormGroup">
                <app-cotisation [formG]="assureFormGroup" [type]="'assure'" (recalc)="recalc($event)" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
                </app-cotisation>
              </div>
            </div>
            <div class="col-md-6" *ngIf="affaire.isTwoAssure">
              <div class="col-sm-12 col-sm-offset-1" *ngIf="affaire.hasConjoint">
                <h2>Votre conjoint</h2>
              </div>
              <div class="col-sm-12 col-sm-offset-1" *ngIf="!affaire.hasConjoint">
                <h2>2<span style="text-transform: lowercase;"><sup>ème</sup></span>
                  assuré</h2>
              </div>
              <div [formGroup]="secondAssureFormGroup">
                <app-cotisation [formG]="secondAssureFormGroup" [type]="'secondAssure'" (recalc)="recalc($event)"
                [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}" *ngIf="affaire.isTwoAssure">
                </app-cotisation>
              </div>
            </div>
          </div>

          <div [formGroup]="assureFormGroup">
            <div formGroupName="tarif">
              <div class="row">
                <div class=" info">
                  <div class="definition">
                    <img class="picto" src="../../../assets/img/ampoule.svg">
                    <label class="astuce">Définitions</label><br>
                    <span><strong>La cotisation viagère</strong> (durée à vie) : Vous réglez des cotisations tout au
                      long de
                      votre vie. </span><br>
                    <span>Le + : <strong>vos cotisations sont moins élevées</strong> qu'en cotisations temporaires.
                    </span>
                    <br>
                    <br>
                    <span *ngIf="appName == enumApp.GROUPAMA"><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                      déterminée (entre 2 et 20 ans). </span>
                      <span *ngIf="appName !== enumApp.GROUPAMA"><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                        déterminée (entre 10 et 20 ans). </span>
                      <br>
                  </div>
                </div>
              </div>

              <div class="row" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
                <div class="col-md-6">
                  <mat-form-field appearance="outline" class="field">
                    <mat-label>Périodicité de paiement (*)</mat-label>
                    <mat-select formControlName="periodicite" (selectionChange)="save()">
                      <mat-option *ngFor="let periodicite of periodicites" [value]="periodicite.code">
                        {{ periodicite.libelle }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="enumApp.GAN_PREV">

            <div class="row">
              <div class="col-md-6">
                <h2>Vous</h2>
                <div [formGroup]="assureFormGroup">
                  <app-cotisation [formG]="assureFormGroup" [type]="'assure'" (recalc)="recalc($event)" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
                  </app-cotisation>
                </div>
              </div>
              <div class="col-md-6" *ngIf="affaire.isTwoAssure">
                <div class="col-sm-12 col-sm-offset-1" *ngIf="affaire.hasConjoint">
                  <h2>Votre conjoint</h2>
                </div>
                <div class="col-sm-12 col-sm-offset-1" *ngIf="!affaire.hasConjoint">
                  <h2>2<span style="text-transform: lowercase;"><sup>ème</sup></span>
                    assuré</h2>
                </div>
                <div [formGroup]="secondAssureFormGroup">
                  <app-cotisation [formG]="secondAssureFormGroup" [type]="'secondAssure'" (recalc)="recalc($event)"
                  [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}" *ngIf="affaire.isTwoAssure">
                  </app-cotisation>
                </div>
              </div>
            </div>
  
            <div [formGroup]="assureFormGroup">
              <div formGroupName="tarif">
                <div class="row">
                  <div class=" info">
                    <div class="definition">
                      <img class="picto" src="../../../assets/img/ampoule.svg">
                      <label class="astuce">Définitions</label><br>
                      <span><strong>La cotisation viagère</strong> (durée à vie) : Vous réglez des cotisations tout au
                        long de
                        votre vie. </span><br>
                      <span>Le + : <strong>vos cotisations sont moins élevées</strong> qu'en cotisations temporaires.
                      </span>
                      <br>
                      <br>
                      <span *ngIf="appName == enumApp.GROUPAMA"><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                        déterminée (entre 2 et 20 ans). </span>
                        <span *ngIf="appName !== enumApp.GROUPAMA"><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                          déterminée (entre 10 et 20 ans). </span>
                        <br>
                    </div>
                  </div>
                </div>
  
                <div class="row" [ngClass]="{'gan-theme': appName !== enumApp.GROUPAMA}">
                  <div class="col-md-6">
                    <mat-form-field appearance="outline" class="field">
                      <mat-label>Périodicité de paiement (*)</mat-label>
                      <mat-select formControlName="periodicite" (selectionChange)="save()">
                        <mat-option *ngFor="let periodicite of periodicites" [value]="periodicite.code">
                          {{ periodicite.libelle }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>

      <br>

      <div class="row">
        <div class="col-md-6">
          <mat-card class="tarifs">
            <mat-card-content>
              <div class="row">
                <div class="col" style="flex: 0 0 100px;">
                  <img src="../../assets/img/picto devis.svg" *ngIf="appName == 'GROUPAMA'">
                  <img src="../../assets/img/picto devis_gan.svg" *ngIf="appName !== 'GROUPAMA'">
                </div>
                <div class="col">
                  <label class="big-label">Votre devis</label>
                  <br>
                  <span class="price">{{totalACAffiche}}<span class="price-sub">{{periodicite}}</span></span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div>
        <br>
        <h6 *ngIf="affaire.isTwoAssure && affaire.hasConjoint" class="text-center">(Vous bénéficiez de 10% de réduction
          sur la
          cotisation du contrat de votre conjoint)</h6>
        <h6 *ngIf="affaire.isTwoAssure && !affaire.hasConjoint" class="text-center">(Vous bénéficiez de 5% de réduction
          sur la
          cotisation de vos deux contrats souscrits simultanément)</h6>
      </div>
      <br>
      <br>
      <div>
        <button mat-button matStepperPrevious class="nav-retour">
          <img src="../../assets/img/left.svg">
          Etape précédente
        </button>

        <button mat-button (click)="sendMail();" class="orange2" [disabled]="isDisabled()">
          RECEVOIR MON DEVIS
        </button>
      </div>
    </mat-step>

    <mat-step state="step4">
      <ng-template matStepLabel>Merci</ng-template>
      <br>

      <div class="row">
        <div class="col-md-12">
          <div class="Feedback">
            <div style="text-align: center">
              <div class="Oval centerOval">
                <img src="../../assets/img/group-11.png" class="Group-11 centerGroup" *ngIf="appName == 'GROUPAMA'">
                <img src="../../assets/img/group-11_gan.png" class="Group-11 centerGroup" *ngIf="appName !== 'GROUPAMA'">
              </div>
              
              <div class="Merci-votre-devis-v centerMerci">Merci, votre devis<br>
                  vous a bien été envoyé par e-mail !</div>
              <div class="Nous-vous-remercions centerNousRemercions" *ngIf="appName == 'GROUPAMA'">
                  Nous vous remercions pour l’intérêt que vous portez à Groupama.
              </div>
              <div class="Nous-vous-remercions centerNousRemercions" *ngIf="appName !== 'GROUPAMA'">
                Nous vous remercions pour l’intérêt que vous portez à Gan Patrimoine.
            </div>
            </div>
            <br>
            <br>
            <div style="text-align: center;">
              <button mat-button class="orange3 centerRetour" (click)="returnHome()" >
                <span class="text-button">RETOUR SUR LA PAGE D'ACCUEIL</span>
            </button>
            </div>
          </div>
        </div>
      </div>

    </mat-step>

  </mat-horizontal-stepper>

</div>
<app-footer-layout></app-footer-layout>