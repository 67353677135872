import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from "../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(JSON.stringify(error));
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  create(data, type): Observable<any> {
    const url = `${apiUrl}/` + type + `/save`;
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  save(data): Observable<any> {
    const url = `${apiUrl}/save/` + environment.nameApp;
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  update(data, type): Observable<any> {
    const url = `${apiUrl}/` + type + `/update`;
    return this.http.put(url, data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  get(type): Observable<any> {
    return this.http.get(apiUrl + `/` + type, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getById(id: string, type): Observable<any> {
    const url = `${apiUrl}/` + type + `/${id}`;
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  getType(id: string, type): Observable<any> {
    const url = `${apiUrl}/` + type + `/${id}`;
    return this.http.get(url, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }
  post(data): Observable<any> {
    const url = `${apiUrl}/tarification`;
    return this.http.post(url, data, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }



}
