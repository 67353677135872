import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SimulateurComponent } from './simulateur.component';
import { HeaderLayoutComponent } from '../header-layout/header-layout.component';




const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    children: [{
      path: '',
      component: SimulateurComponent
    }]
  },
  {
    path: ':urlFrom',
    component: HeaderLayoutComponent,
    children: [{
      path: '',
      component: SimulateurComponent
    }]
  },
  {
    path: ':urlFrom/mail',
    component: HeaderLayoutComponent,
    children: [{
      path: '',
      component: SimulateurComponent
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimulateurRoutingModule { }
