import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { AppNameEnum } from '../../shared/enum/appName.enum';

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss']
})
export class FooterLayoutComponent implements OnInit {
  nameApp = environment.nameApp
  enumApp = AppNameEnum;


  constructor() { 
  }

  ngOnInit(): void {
  }
}
