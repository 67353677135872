<div [ngSwitch]="nameApp">

    <div *ngSwitchCase="enumApp.GAN_PREV">
        <div class="footer-section">
            <div class="inner">
                <div style="height: 360px;">
                    <p style="color: #ffffff; text-decoration: underline; font-size: 1.4em; font-weight: bold">Joindre
                        le service
                        client de Gan Prévoyance :<br> </p>
                    <p style="color: #ffffff; font-size: 1.2em; font-weight: bold">09.69.32.35.05 - Choix 4 (appel non
                        surtaxé)<br>
                    </p>
                    <p style="color: #ffffff; font-size: 1.2em; font-weight: bold">Du lundi au vendredi de 8h30 à 18h
                    </p>
                    <ul class="services links">
                        <li>
                            <span style="font-weight: bold; font-size: 1.2em;color:white">Nos assurances</span>
                        </li>
                        <li>
                            <a
                                href="https://www.ganprevoyance.fr/assurance-prevoyance-famille"><span>Prévoyance</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/retraite"><span>Retraite</span></a>
                        </li>
                        <li><a href="https://www.ganprevoyance.fr/sante/complementaire-sante"><span>Santé</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/accidents"><span>Accidents</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/epargne"><span>Epargne</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/professionnel"><span>Professionnel</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/professionnel"><span>Par métier</span></a>
                        </li>
                    </ul>
                    <ul class="services links">
                        <li>
                            <span style="font-weight: bold; font-size: 1.2em;color:white">GAN Prévoyance</span>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/qui-sommes-nous"><span>Qui sommes-nous ?</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/espace-client"><span>Espace client</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/faq"><span>F.A.Q</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/recrutement"><span>Recrutement</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/reclamations"><span>Réclamation</span></a>
                        </li>
                    </ul>
                    <ul class="services links">
                        <li>
                            <span style="font-weight: bold; font-size: 1.2em;color:white">Informations</span>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/conseils-assurance"><span>Nos conseils</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/actualites"><span>Actualités</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/professionnel/conferences"><span>En région</span></a>
                        </li>
                    </ul>
                    <ul class="services links">
                        <li>
                            <span style="font-weight: bold; font-size: 1.2em;color:white">Contactez-nous</span>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/contact-conseiller"><span>Formulaire de
                                    contact</span></a>
                        </li>
                        <li>
                            <a href="https://rendezvous-enligne.ganprevoyance.fr/steps"><span>Prendre rendez-vous en
                                    ligne</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/service-clients"><span>Nous appeler</span></a>
                        </li>
                        <li>
                            <a href="https://www.ganprevoyance.fr/contact-conseiller"><span> Être rappelé</span></a>
                        </li>
                    </ul>
                </div>

                <div class="social">
                    <a href="https://fr-fr.facebook.com/page.gan.prevoyance/"> <img
                            src="https://www.ganprevoyance.fr//documents/20194/20790/Facebook-logo.png/95751a69-1f76-4975-92ef-2dd26513678d?t=1477570582000"
                            alt="" class="picto" style="vertical-align: inherit; width: 33px;"></a>
                    <a href="https://fr.linkedin.com/company/gan-pr%C3%A9voyance"> <img
                            src="https://www.ganprevoyance.fr/documents/20194/0/Logo+LinkedIn/31d1aab8-98ee-4b92-a1b2-7c5c3f62036a?t=1570539655000"
                            alt="" class="picto" style="vertical-align: inherit; width: 33px;"></a>
                </div>

                <div class="disclaimer">
                    <ul>
                        <li><a href="https://www.ganprevoyance.fr//mentions-legales">Mentions légales</a></li>
                        <li><a href="https://www.ganprevoyance.fr/plan-du-site">Plan du site</a></li>
                        <li><a href="https://www.ganprevoyance.fr/cookies">Cookies</a></li>
                        <li><a href="https://www.ganprevoyance.fr/donnees-personnelles">Données personnelles</a></li>
                    </ul>
                </div>
            </div>
        </div>


    </div>

</div>



<router-outlet></router-outlet>