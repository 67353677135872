import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { AppNameEnum } from '../../shared/enum/appName.enum';

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent implements OnInit {
  nameApp = environment.nameApp
  enumApp = AppNameEnum;
  urlFrom: any;
  mailCommercial: string = "";
  telCommercial: string = "";
  editMode: boolean = false;
  navVisible: boolean;

  constructor(private router: Router,		private activatedRoute: ActivatedRoute) { 
    this.urlFrom = this.activatedRoute.snapshot.paramMap.get('urlFrom');
  }

  ngOnInit(): void {
    this.mailCommercial = localStorage.getItem('mailCommercial_Simulateur');
    this.telCommercial = localStorage.getItem('telCommercial_Simulateur');

    if (!this.mailCommercial || !this.telCommercial) this.editMode = true;
  }

  editMail() {
    this.editMode = true;
  }

  validMail(){
    localStorage.setItem('telCommercial_Simulateur', this.telCommercial);
    localStorage.setItem('mailCommercial_Simulateur', this.mailCommercial);

    this.editMode = false;
    this.router.navigate([`/GCA/mail`]);
  }
}
