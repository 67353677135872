import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AppNameEnum } from '../../../shared/enum/appName.enum';
import { environment } from '../../../environments/environment';
import * as $ from "jquery";

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input()  isConjoint:boolean;
  @Input()  isPromotionDisplay:boolean;
  
  checkValue: boolean = null;
  nameApp = environment.nameApp
  enumApp = AppNameEnum;

  constructor(
    protected cdr: ChangeDetectorRef) {
   }

    ngOnInit() {
    }

    ngOnDestroy() {}

    selectPromotion(isChecked) {
      this.checkValue = isChecked;
      this.cdr.markForCheck();
      this.onClick.emit({isChecked: this.checkValue});
    }

}
