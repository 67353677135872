export const APP_CONSTANTS = {
  LOCALE      :  'fr-FR',
  REDUCTION   :  {
    CONJOINT : 0.9,
    SECOND_ASSURE : 0.95
  },
  PERIODICITE : {
    ANNUELLE      : {
      code    : "1",
      title   : "Annuelle",
      libelle : "/an"
    },
    SEMESTRIELLE  : {
      code    : "2",
      title   : "Semestrielle",
      libelle : "/semestre"
    },
    TRIMESTRIELLE : {
      code    : "3",
      title   : "Trimestrielle",
      libelle : "/trimestre"
    },
    MENSUELLE      : {
      code    : "4",
      title   : "Mensuelle",
      libelle :  "/mois"
    }
  },
    SITUATION_FAMILIALES: [
        {
            libelle : "Marié(e)",
            code    : "1"
        },
        {
            libelle : "Célibataire",
            code    : "2"
        },
        {
            libelle : "Divorcé(e)",
            code    : "3"
        },
        {
            libelle : "Veuf(ve)",
            code    : "4"
        },
        {
            libelle : "Pacsé(e)",
            code    : "5"
        },
        {
            libelle : "Vie Maritale",
            code    : "6"
        }
    ],

};

