import { Component, OnInit } from '@angular/core';
import { environment } from "../environments/environment";
import { UserLocalStorageService } from 'src/services/userLocalStorageService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public nameApp = environment.nameApp;
  title = 'simulateurObs';

  constructor(private userLocalStorageService: UserLocalStorageService) { }

  ngOnInit() {
    // init timestamp
    this.userLocalStorageService.default();
  }
}
