import { NgModule } from '@angular/core';
import { TrackingLibComponent } from './tracking-lib.component';



@NgModule({
  declarations: [],
  imports: [
  ],
  providers : [TrackingLibComponent],
  exports: []
})
export class TrackingLibModule { }
