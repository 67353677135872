<form [formGroup]="formG">
    <div formGroupName="tarif">
        <div class="row">
            <div [ngClass]="{'col-md-6': appName == enumApp.GROUPAMA, 'col-md-12': appName !== enumApp.GROUPAMA}">
                <mat-form-field appearance="outline" class="field">
                    <mat-label>Montant du capital décés</mat-label>
                    <input matInput placeholder="" formControlName="montantCapitalDeces" required
                        (change)="updateTarification()">
                    <span matSuffix>€</span>

                    <mat-error *ngIf="formG.controls.tarif['controls'].montantCapitalDeces.invalid">Le montant minimum
                        est
                        850€. Le capital
                        décés garanti doit être compris entre 850€ et 15.000€</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div>
            <label class="std-label">Type de cotisation</label>
            <br />
            <div class="row" style="margin-top: 16px">
                <div [ngClass]="{'col-md-6': appName == enumApp.GROUPAMA, 'col-md-12': appName !== enumApp.GROUPAMA}"
                    style="display: flex;">
                    <button mat-stroked-button type="button" class="button-select image-centered"
                        [color]="selectedTypePrime == 'viagere' ? 'primary' : 'basic'"
                        (click)="selectTypePrime('viagere')" [ngClass]="{'selected': selectedTypePrime == 'viagere'}">
                        <img src="../../../assets/img/refresh.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'fixe'}">
                        <img src="../../../assets/img/refresh_green.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'viagere'}"
                            *ngIf="appName == 'GROUPAMA'">
                        <img src="../../../assets/img/refresh_gan.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'viagere'}"
                            *ngIf="appName !== 'GROUPAMA'">
                        Viagère
                    </button>
                    <button mat-stroked-button type="button" class="button-select image-centered"
                        [color]="selectedTypePrime == 'fixe' ? 'primary' : 'basic'" (click)="selectTypePrime('fixe')"
                        style="margin-left: 16px;" [ngClass]="{'selected': selectedTypePrime == 'fixe'}">
                        <img src="../../../assets/img/clock.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'viagere'}">
                        <img src="../../../assets/img/clock_green.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'fixe'}"
                            *ngIf="appName == 'GROUPAMA'">
                        <img src="../../../assets/img/clock_gan.svg" style="display: none;"
                            [ngClass]="{'refresh':true, 'visible' : selectedTypePrime == 'fixe'}"
                            *ngIf="appName !== 'GROUPAMA'">
                        Temporaire
                    </button>
                </div>
            </div>

        </div>
        <br>
        <!-- <div class=" info">
            <div class="definition">
                <img class="picto" src="../../../assets/img/ampoule.svg">
                <label class="astuce">Définitions</label><br>
                <span><strong>La cotisation viagère</strong> (durée à vie) : Vous réglez des cotisations tout au long de
                    votre vie. </span><br>
                <span>Le + : <strong>vos cotisations sont moins élevées</strong> qu'en cotisations temporaires. </span>
                <br>
                <br>
                <span><strong>La cotisation temporaire</strong> : Vous réglez des cotisations pendant une durée
                    déterminée (entre 2 et 20 ans). </span><br>
            </div>
        </div> -->

        <br *ngIf="isPrimeFixe">

        <div class="row" *ngIf="isPrimeFixe">

            <div [ngClass]="{'col-md-6': appName == enumApp.GROUPAMA, 'col-md-12': appName !== enumApp.GROUPAMA}">
                <mat-form-field appearance="outline" class="field">
                    <mat-label>Durée souhaitée des cotisations</mat-label>
                    <mat-select formControlName="duree" (selectionChange)="updateTypePrime()">
                        <mat-option *ngFor="let duree of durees" [value]="duree.value">
                            {{ duree.legend }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
    </div>
</form>