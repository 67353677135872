import { Injectable, Inject } from '@angular/core';
import * as moment from "moment";
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

const ID_USER = 'ID_USER';

@Injectable()
export class UserLocalStorageService {


  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
  }


  default(): void {
    this.storage.set(ID_USER, moment().unix().toString());
  }

  getUserID(): any {
    return JSON.parse(this.storage.get(ID_USER));
  }

}
