import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AppNameEnum } from '../../../shared/enum/appName.enum';

@Component({
  selector: 'app-cotisation',
  templateUrl: './cotisation.component.html',
  styleUrls: ['./cotisation.component.scss']
})
export class CotisationComponent implements OnInit {
  @Input()  formG: FormGroup;
  @Input()  type: string;
  @Output() recalc = new EventEmitter<any>();

  appName = environment.nameApp;
  enumApp = AppNameEnum;
  isPrimeFixe:boolean = false;
  value: number = 2;
  // durees = [
  //   { value: 2, legend: '2 ans' },
  //   { value: 3, legend: '3 ans' },
  //   { value: 4, legend: '4 ans' },
  //   { value: 5, legend: '5 ans' },
  //   { value: 6, legend: '6 ans' },
  //   { value: 7, legend: '7 ans' },
  //   { value: 8, legend: '8 ans' },
  //   { value: 9, legend: '9 ans' },
  //   { value: 10, legend: '10 ans' },
  //   { value: 11, legend: '11 ans' },
  //   { value: 12, legend: '12 ans' },
  //   { value: 13, legend: '13 ans' },
  //   { value: 14, legend: '14 ans' },
  //   { value: 15, legend: '15 ans' },
  //   { value: 16, legend: '16 ans' },
  //   { value: 17, legend: '17 ans' },
  //   { value: 18, legend: '18 ans' },
  //   { value: 19, legend: '19 ans' },
  //   { value: 20, legend: '20 ans' }
  // ]

  durees = [
    { value: 10, legend: '10 ans' },
    { value: 11, legend: '11 ans' },
    { value: 12, legend: '12 ans' },
    { value: 13, legend: '13 ans' },
    { value: 14, legend: '14 ans' },
    { value: 15, legend: '15 ans' },
    { value: 16, legend: '16 ans' },
    { value: 17, legend: '17 ans' },
    { value: 18, legend: '18 ans' },
    { value: 19, legend: '19 ans' },
    { value: 20, legend: '20 ans' }
  ]

  dureesGanPat = [
    { value: 10, legend: '10 ans' },
    { value: 11, legend: '11 ans' },
    { value: 12, legend: '12 ans' },
    { value: 13, legend: '13 ans' },
    { value: 14, legend: '14 ans' },
    { value: 15, legend: '15 ans' },
    { value: 16, legend: '16 ans' },
    { value: 17, legend: '17 ans' },
    { value: 18, legend: '18 ans' },
    { value: 19, legend: '19 ans' },
    { value: 20, legend: '20 ans' }
  ]
  selectedTypePrime = "";

  constructor( protected cdr: ChangeDetectorRef) {
    // if (this.appName == AppNameEnum.GAN_PAT){
    //   this.durees = this.dureesGanPat;
    // }
   }

  ngOnInit(): void {
    this.selectTypePrime(this.formG.value.tarif.typePrime);
    this.cdr.markForCheck();
    
  }
  
  ngAfterViewInit(): void {
  }
  

  selectTypePrime(idName) {
    switch (idName) {
      case "viagere":
        this.selectedTypePrime = "viagere";
        this.isPrimeFixe = false;
        this.formG.patchValue({
          tarif : {
            typePrime : idName
          }
        })
        break;

      case "fixe":
        this.selectedTypePrime = "fixe";
        this.isPrimeFixe = true;
        this.formG.patchValue({
          tarif : {
            typePrime : idName
          }
        })
        break;

      default:
        break;
    }

    this.updateTarification();

  }

  updateTypePrime() {
    this.formG.value.tarif.typePrime = "fixe";
    this.updateTarification();
  }

  updateTarification(duree?) {
    this.recalc.emit({
        assure       : this.formG,
        type        : this.type
    });
}

}
