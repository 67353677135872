import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SimulateurComponent } from './simulateur/simulateur.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { SimulateurModule } from './simulateur/simulateur.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Ng5SliderModule } from 'ng5-slider';
import { HttpModule } from '@angular/http';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// import { HeaderLayoutModule } from './header-layout/header-layout.module';
import { UserLocalStorageService } from 'src/services/userLocalStorageService';
import { TrackingLibModule, TrackingLibComponent } from 'src/libs/tracking-lib';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { FooterLayoutComponent } from './footer-layout/footer-layout.component';

const maskConfig: Partial<IConfig> = {
  showMaskTyped:     true
};

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
  declarations: [
    AppComponent,
    HeaderLayoutComponent,
    FooterLayoutComponent,
    SimulateurComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
    MaterialModule,
    // HeaderLayoutModule,
    AppRoutingModule,
    SimulateurModule,
    Ng5SliderModule,
    TrackingLibModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
  providers: [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  UserLocalStorageService,
  TrackingLibComponent,
  {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: { displayDefaultIndicatorType: true }
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
