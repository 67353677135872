import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';


export class CustomValidators {
    static dateValidator(dateMin, dateMax): ValidatorFn {
        
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control && control.value &&  moment(control.value, "DDMMYYYY").isBetween(dateMin, dateMax)) {
                return null;
            }
            return { 'dateValidator': true };
        }
    }


    // static codePostalValidator(): ValidatorFn {
    //     return (control: AbstractControl): { [key: string]: any } | null => {
    //         if (control && control.value &&  ) {
    //             return null;
    //         }
    //         return { 'dateValidator': true };
    //     }
    // }
}
