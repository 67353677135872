<div [ngSwitch]="nameApp">
    <div *ngSwitchCase="enumApp.GROUPAMA">
        <div *ngIf="urlFrom == 'GCA'">
            <header>
                <mat-toolbar color="primary" style="background-color: #006a53;">
                    <mat-toolbar-row style="height: 60px;">

                        <div class="logo-container">
                            <div class="logo">
                                <img src="./assets/img/logo-groupama.png">
                            </div>
                        </div>

                        <div style="display: flex; flex-direction: column;">
                            <h1 style="line-height: 28px;font-weight: 700;">Simulateur Obsèques</h1>
                            <span style="font-size: 12px; line-height: 12px;">Découvrez la solution adaptée à vos
                                souhaits et à votre situation familiale,<br />avec la certitude de soulager vos proches
                                le moment venu.</span>
                        </div>

                        <div style="flex:1"></div>

                        <div style="display:flex; align-items: center;">
                            <div *ngIf="mailCommercial && telCommercial" style="font-size: 14px;line-height: 14px;">
                                <mat-icon matPrefix style="font-size: 14px;">email</mat-icon>
                                &nbsp;{{mailCommercial}}<br />
                                <mat-icon matPrefix style="font-size: 14px;">phone</mat-icon>&nbsp;{{telCommercial}}
                            </div>
                            <button matSuffix mat-icon-button (click)="editMail()"
                                matTooltip="Editer les coordonnées de l'agence">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </mat-toolbar-row>

                </mat-toolbar>
            </header>

            <div class="container" *ngIf="editMode" style="margin-top: 100px;">
                <h1>Merci de renseigner l'email et le téléphone de l'agence ou du commercial</h1>
                <p>Ces coordonnées seront affichées sur le Devis</p>

                <div class="text-center" style="margin-top: 50px;">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-icon matPrefix>email</mat-icon>
                        <mat-label>Email de l'agence/commercial</mat-label>
                        <input matInput [(ngModel)]="mailCommercial">
                    </mat-form-field>

                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-icon matPrefix>phone</mat-icon>
                        <mat-label>Téléphone de l'agence</mat-label>
                        <input matInput [(ngModel)]="telCommercial">
                    </mat-form-field>

                    <button mat-flat-button (click)="validMail()" color="primary">
                        <mat-icon style="display: inline;">done_outline</mat-icon> Valider
                    </button>
                </div>
            </div>

        </div>
    </div>

    <div *ngSwitchCase="enumApp.GAN_PAT">
        <!-- <header>
            <mat-toolbar color="primary" style="background-color: #001F49;">
                <mat-toolbar-row style="height: 60px;">

                    <div class="logo-container">
                        <div class="logo-gpat">
                            <img src="./assets/img/gan-patrimoine.png">
                        </div>
                    </div>
                </mat-toolbar-row>

            </mat-toolbar>

            <div class="g-immersive">
                <div class="presentation-overlay"></div>
                <div class="g-immersive__container">
                    <h1 class="g-immersive__title"> Simulation tarif assurance obsèques </h1>
                    <p class="g-immersive__subtitle"> <span class="g-immersive__subtitle-content"> Découvrez la solution
                            adaptée avec la certitude de soulager vos proches le moment venu. <br> </span> </p>
                </div>
            </div>

            <div class="pro-bloc">
                <h3>Simuler votre tarif Assurance Obsèques en quelques clics</h3>
            </div>
        </header> -->
    </div>

    <div *ngSwitchCase="enumApp.GAN_PREV">
        <div class="header">
            <h2 id="logos"><a href="http://www.ganprevoyance.fr/"> <img class="logo"
                        src="https://www.ganprevoyance.fr/documents/20194/22607/Logo+gan+prev.png/f47f1fd3-046f-4517-8fd2-c8230af42ac4?t=1479199021000"
                        alt="" title=""></a></h2>
            <div id="secondary" class="nav-secondary nav-secondary-particuliers">
                <ul>
                    <li class="active"><a href="https://www.ganprevoyance.fr/particulier">Particulier</a></li>
                    <li><a href="https://www.ganprevoyance.fr/professionnel">Professionnel</a></li>
                </ul>
            </div>
            <div class="subheader">
                <nav id="navigation" role="navigation">
                    <ul id="yui_patched_v3_11_0_1_1518440949772_124">
                        <li id="yui_patched_v3_11_0_1_1518440949772_209" class="activeNavigation"><a
                                href="https://www.ganprevoyance.fr/particulier/protection-famille" tabindex="-1"
                                id="yui_patched_v3_11_0_1_1518440949772_145"><span
                                    id="yui_patched_v3_11_0_1_1518440949772_208">PREVOYANCE</span></a>
                        </li>
                        <li id="yui_patched_v3_11_0_1_1518440949772_213" class=""><a
                                href="https://www.ganprevoyance.fr/retraite" tabindex="-1"
                                id="yui_patched_v3_11_0_1_1518440949772_147"><span
                                    id="yui_patched_v3_11_0_1_1518440949772_215">RETRAITE</span></a>
                        </li>
                        <li id="yui_patched_v3_11_0_1_1518440949772_178" class=""><a
                                href="https://www.ganprevoyance.fr/sante/complementaire-santet" tabindex="-1"
                                id="yui_patched_v3_11_0_1_1518440949772_149"><span
                                    id="yui_patched_v3_11_0_1_1518440949772_177">SANTE</span></a>
                        </li>
                        <li><a href="https://www.ganprevoyance.fr/accidents" tabindex="-1"
                                id="yui_patched_v3_11_0_1_1518440949772_151"><span>ACCIDENTS</span></a></li>
                        <li id="yui_patched_v3_11_0_1_1518440949772_174" class=""><a
                                href="https://www.ganprevoyance.fr/epargnee" tabindex="-1"
                                id="yui_patched_v3_11_0_1_1518440949772_153"><span>EPARGNE</span></a></li>
                    </ul>
                </nav>
            </div>
            <div class="breadcrumb">
                <ul xmlns:v="http://rdf.data-vocabulary.org/#">
                    <li typeof="v:Breadcrumb"><a href="https://www.ganprevoyance.fr/particulier" rel="v:url"
                            property="v:title">Particulier</a></li>
                    <li rel="v:child" typeof="v:Breadcrumb">PREVOYANCE</li>
                </ul>
            </div>
        </div>

        <div class="header-mobile">
            <div id="logos">
                <a href="http://www.ganprevoyance.fr/"> <img class="logo"
                        src="https://www.ganprevoyance.fr/documents/20194/22607/Logo+gan+prev.png/f47f1fd3-046f-4517-8fd2-c8230af42ac4?t=1479199021000"
                        alt="" title=""></a>
            </div>
            <div class="subheader">
                <a class="button menu" (click)="navVisible=!navVisible">Menu</a>
                <a class="button fa fa-unlock-alt" id="ecli"
                    href="https://authentification.ganprevoyance.fr/cas/login?service=https%3A%2F%2Fespaceclient.ganprevoyance.fr%2Fwps%2Fmyportal%2FTableauDeBord%3Fidcr%3D96"></a>
                <a class="button fa fa-phone" href="tel:0969323505"></a>
                <a class="button fa fa-calendar" href="https://rendezvous-enligne.ganprevoyance.fr/steps"></a>
            </div>
            <nav id="navigation" role="navigation" *ngIf="navVisible">
                <ul id="yui_patched_v3_11_0_1_1518440949772_124">
                    <li id="yui_patched_v3_11_0_1_1518440949772_209" class="activeNavigation"><a
                            href="https://www.ganprevoyance.fr/particulier/protection-famille" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_145"><span
                                id="yui_patched_v3_11_0_1_1518440949772_208">Protéger
                                votre famille</span></a>
                    </li>
                    <li id="yui_patched_v3_11_0_1_1518440949772_213" class=""><a
                            href="https://www.ganprevoyance.fr/particulier/epargne-fiscalite" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_147"><span
                                id="yui_patched_v3_11_0_1_1518440949772_215">Optimiser
                                votre épargne</span></a>
                    </li>
                    <li id="yui_patched_v3_11_0_1_1518440949772_178" class=""><a
                            href="https://www.ganprevoyance.fr/particulier/assurance-pret" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_149"><span
                                id="yui_patched_v3_11_0_1_1518440949772_177">Assurer
                                votre crédit</span></a>
                    </li>
                    <li><a href="https://www.ganprevoyance.fr/particulier/assurance-sante" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_151"><span>Rembourser
                                vos frais de santé</span></a></li>
                    <li id="yui_patched_v3_11_0_1_1518440949772_174" class=""><a
                            href="https://www.ganprevoyance.fr/particulier/preparer-retraite" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_153"><span>Préparer votre retraite</span></a></li>
                    <li><a href="https://www.ganprevoyance.fr/particulier/bien-vivre-retraite" tabindex="-1"
                            id="yui_patched_v3_11_0_1_1518440949772_155"><span>Compléter
                                votre retraite</span></a></li>
                </ul>
            </nav>
        </div>


    </div>

</div>



<router-outlet></router-outlet>