export class Tarif {

    periodicite        : string;
    duree              : number;
    typePrime          : string;
    montantCapitalDeces: number;
    gainAvecRemise     : number;
    totalDC            : number;
    totalAssistance    : number;
    cotisation         : number;

    constructor() {
      this.duree               = 10;
      this.typePrime           = "viagere";
      this.montantCapitalDeces = 4000;
      this.gainAvecRemise      = 0;
      this.totalDC             = 0;
      this.totalAssistance     = 0;
      this.periodicite         = "4";
      this.cotisation          = 0;
    }


    getTotaAnnualise(): number {
        return (this.totalDC + this.totalAssistance);
    }

    getTotal(): number {
        return ((this.totalDC + this.totalAssistance) / this.getPeriodiciteDiviseur()) || undefined;
    }

    getTotalDeces(): number {
        return ((this.totalDC) / this.getPeriodiciteDiviseur()) || undefined;
    }

    getTotalAssistance(): number {
        return ((this.totalAssistance) / this.getPeriodiciteDiviseur()) || undefined;
    }

    getPeriodiciteDiviseur(): number {
        let diviseur = 12;
        switch (this.periodicite) {
          case "1" :
            diviseur = 1;
            break;
          case "2" :
            diviseur = 2;
            break;
          case "3" :
            diviseur = 4;
            break;
          case "4" :
            diviseur = 12;
            break;
        }
        return diviseur;
    }

    getPeriodiciteLibelle(): string {
        let libelle = "";
        switch (this.periodicite) {
          case "1" :
            libelle = "/an";
            break;
          case "2" :
            libelle = "/semestre";
            break;
          case "3" :
            libelle = "/trimestre";
            break;
          case "4" :
            libelle = "/mois";
            break;
        }
        return libelle;
    }
}
