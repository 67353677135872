import { Assure }    from "./assure";
import { Tarif }    from "./tarif";

export class Affaire {

  public ID_USER          : any;
  public step             : string;
  public statut           : string;
  public dateMiseAJour    : string;
  public mailCommercial   : string;
  public telCommercial    : string;

  public isTwoAssure      : boolean;
  public hasConjoint      : boolean;
  public assure           : Assure;
  public secondAssure     : Assure;
  public totalAc          : number;

  // public tarifs           : Tarif[];

  constructor() {
    this.assure            = new Assure;
    this.secondAssure      = new Assure;
    this.mailCommercial   = "";
    this.isTwoAssure      = false;
    this.hasConjoint      = false;
    this.statut           = "En Cours";
  }
}

export class Total {
  totalAll: number;
  periodeAssure: string = "";
  periodeConjoint: string = "";
  totalDcAssure: number = 0;
  totalDcConjoint: number = 0;
  totalAssistanceAssure: number = 0;
  totalAssistanceConjoint: number = 0;
}
