import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from "moment";
import { environment } from 'src/environments/environment';
import { AppNameEnum } from 'src/shared/enum/appName.enum';

@Component({
  selector: 'formulaire',
  templateUrl: './formulaire.component.html',
  styleUrls: ['./formulaire.component.scss']
})
export class FormulaireComponent implements OnInit {

  @Input() isControl: boolean;;
  @Input() formG: FormGroup;
  @Input() type: string;
  appName = environment.nameApp;
  enumApp = AppNameEnum;
  selectedCivilite = "";
  startDate = new Date(1970, 0, 1);

  datePrevious: string;

  constructor() { }

  ngOnInit(): void {
    this.startDate = new Date(moment().subtract(40, 'years').toDate());
  }

  selectCivilite(idName) {
    this.selectedCivilite = idName;

    this.formG.patchValue({
      civilite: idName
    })

  }

  dateChange(event) {
    this.formG.patchValue({
      dateNaissance: moment(event.target.value).format("DD/MM/YYYY")
    })
  }

}
