import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import {Http} from '@angular/http';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Helper} from './helper';
import {Assure} from './assure';
import {APP_CONSTANTS} from '../app.constants';
import {from} from 'rxjs';
import {map, flatMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {Affaire} from './affaire';
import * as Q from 'Q';
import {DecimalPipe} from '@angular/common';
import {RestApiService} from '../../services/rest-api.service';
import * as moment from 'moment';
import {environment} from '../../environments/environment';
import {AppNameEnum} from '../../shared/enum/appName.enum';
import {UserLocalStorageService} from '../../services/userLocalStorageService';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TrackingLibComponent} from '../../libs/tracking-lib';
import * as CryptoJS from 'crypto-js';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, Params} from '@angular/router';

const key = CryptoJS.enc.Utf8.parse('7061737323313233');
const iv = CryptoJS.enc.Utf8.parse('7061737323313233');

@Component({
  selector: 'app-simulateur',
  templateUrl: './simulateur.component.html',
  styleUrls: ['./simulateur.component.scss']
})
export class SimulateurComponent implements OnInit {
  appName = environment.nameApp;
  enumApp = AppNameEnum;
  urlFrom: any;
  @ViewChild('stepper') private myStepper: MatStepper;
  situationFamiliales: any[] = APP_CONSTANTS.SITUATION_FAMILIALES;
  periodicites = [
    {code: APP_CONSTANTS.PERIODICITE.ANNUELLE.code, libelle: APP_CONSTANTS.PERIODICITE.ANNUELLE.title},
    {code: APP_CONSTANTS.PERIODICITE.SEMESTRIELLE.code, libelle: APP_CONSTANTS.PERIODICITE.SEMESTRIELLE.title},
    {code: APP_CONSTANTS.PERIODICITE.TRIMESTRIELLE.code, libelle: APP_CONSTANTS.PERIODICITE.TRIMESTRIELLE.title},
    {code: APP_CONSTANTS.PERIODICITE.MENSUELLE.code, libelle: APP_CONSTANTS.PERIODICITE.MENSUELLE.title}
  ];
  baremes: any[] = [];
  caisses: any[] = [];
  ageMax = 85;
  ageMin = 40;
  totalACAffiche: string;
  periodicite: string;

  isLinear = true;
  isControl = false;
  isSecondControl = false;
  isPromotionDisplay = false;

  affaire: Affaire = new Affaire;
  assure: Assure = new Assure;
  assureFormGroup: FormGroup;
  secondAssure: Assure = new Assure;
  secondAssureFormGroup: FormGroup;

  defaultTag = {
    contact: {origine: 'site marchand'},
    outils_an: {code_postal: '', nom_etape: 'Vous', numero_etape: '1', product_range: 'Simulateur Obseques'},
    contenu: {
      fil_ariane: 'Mon assurance::Simulateur Obseques::Etape 1::Vous',
      marche: 'particulier',
      region_enregistree: 'NAT',
      type_content: 'Simulateur Obseques',
      code_postal: ''
    },
    simulateur: {
      obseque: {conjoint: ''},
      obseques: {
        sf: '',
        montant_capital: '',
        type_cotisation: '',
        duree_cotisation: '',
        periodicité: '',
        tarif_mensuel: '',
        montant_capital_conjoint: '',
        type_cotisation_conjoint: '',
        duree_cotisation_conjoint: ''
      }
    },
    devis: {commun: {hash_nom: '', hash_prenom: '', hash_email_sn: '', hash_phone_sn: '', dob: ''}},
  };

  config = {
    permanentKey: ['contact', 'outils_an', 'contenu', 'simulateur', 'devis'],
    logDebug: true
  };

  constructor(private fb: FormBuilder, private http: Http, public api: RestApiService,
              private trackingService: TrackingLibComponent,
              protected cdr: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute,
              public snackBar: MatSnackBar,
              private userLocalStorageService: UserLocalStorageService) {
    this.urlFrom = this.activatedRoute.snapshot.paramMap.get('urlFrom');
    if (this.urlFrom == null) {
      this.urlFrom = 'Groupama';
    } else {
      this.affaire.mailCommercial = localStorage.getItem('mailCommercial_Simulateur');
      this.affaire.telCommercial = localStorage.getItem('telCommercial_Simulateur');
    }
    moment.locale('fr');
    this.assure.situationFamiliale = '2';

    from(this.http.request('assets/data/bareme-serenite-obseques.json'))
      .pipe(
        map(response => response.json()))
      .subscribe((response) => {
        this.baremes = response;
        this.ageMax = _.findLast(response, ['duree', 'viagere']).age;
        this.ageMin = _.find(response, ['duree', 'viagere']).age;
      });

    from(this.http.request('assets/data/caisses.json'))
      .pipe(
        map(response => response.json()))
      .subscribe((response) => {
        this.caisses = response;
      });
  }

  ngOnInit(): void {
    this.affaire.ID_USER = this.userLocalStorageService.getUserID();
    const dateMin = moment().subtract(this.ageMax, 'years').toDate();
    const dateMax = new Date(moment().subtract(this.ageMin, 'years').toDate());
    this.assureFormGroup = Helper.initForm(this.fb, this.assure, dateMin, dateMax);
    this.secondAssureFormGroup = Helper.initForm(this.fb, this.secondAssure, dateMin, dateMax);
    this.changeSituationFamiliale();
    this.trackingService.initTagging(this.defaultTag, this.config);
    this.tracking(1);
  }

  updateRemise(event: any) {
    this.affaire.isTwoAssure = event.isChecked;
    this.cdr.markForCheck();
  }

  save(step = null) {
    this.isControl = true;
    this.cdr.detectChanges();
    if (this.assureFormGroup.valid) {
      // this.tracking(step);
      this.recalc();
      if (step == 1 && !this.affaire.isTwoAssure) {
        this.tracking(3);
      } else if (step == 1 && this.affaire.isTwoAssure) {
        this.tracking(2);
      } else if (step == 2) {
        this.isSecondControl = true;
        this.tracking(3);
      }
    }
  }

  public tracking(etape) {
    if (environment.nameApp !== AppNameEnum.GROUPAMA) { return; }
    const _self = this;
    let arraySet = [];
    const situationFamiliale = _.find(_self.situationFamiliales, function(o) {
      return o.code == _self.affaire.assure.situationFamiliale;
    });
    const caisse = _.find(_self.caisses, function(o) {
      return o.Departement == _self.affaire.assure.codepostal.substring(0, 2);
    });

    const periodicite = _.find(_self.periodicites, function(o) {
      return o.code == _self.affaire.assure.tarif.periodicite;
    });

    switch (etape) {
      case 1:
        arraySet = [{set: this.affaire.assure.codepostal, to: 'outils_an.code_postal'}];
        this.trackingService.updateAndCommitTag(arraySet, 'simulateur_obseques_e1');
        break;
      case 2:
        arraySet = [
          {set: 'Mon assurance::Simulateur Obseques::Etape 2::Votre Conjoint', to: 'contenu.fil_ariane'},
          {set: this.affaire.assure.codepostal, to: 'outils_an.code_postal'},
          {set: caisse.CR, to: 'contenu.region_enregistree'},
          {set: 'Votre Conjoint', to: 'outils_an.nom_etape'},
          {set: '2', to: 'outils_an.numero_etape'},
          {set: this.affaire.isTwoAssure ? 1 : 0, to: 'simulateur.obseque.conjoint'},
          {set: this.encrypt(this.affaire.assure.nom), to: 'devis.commun.hash_nom'},
          {set: this.encrypt(this.affaire.assure.prenom), to: 'devis.commun.hash_prenom'},
          {set: this.encrypt(this.affaire.assure.email), to: 'devis.commun.hash_email_sn'},
          {set: this.encrypt(this.affaire.assure.telephone), to: 'devis.commun.hash_phone_sn'},
          {set: this.affaire.assure.dateNaissance, to: 'devis.commun.dob'},
          {set: situationFamiliale.libelle, to: 'simulateur.obseques.sf'}
        ];
        this.trackingService.updateAndCommitTag(arraySet, 'simulateur_obseques_e2');
        break;
      case 3:
        arraySet = [
          {set: 'Mon assurance::Simulateur Obseques::Etape 2::Votre Devis', to: 'contenu.fil_ariane'},
          {set: 'Votre Devis', to: 'outils_an.nom_etape'},
          {set: this.affaire.assure.codepostal, to: 'outils_an.code_postal'},
          {set: caisse.CR, to: 'contenu.region_enregistree'},
          {set: '3', to: 'outils_an.numero_etape'},
          {set: this.encrypt(this.affaire.assure.nom), to: 'devis.commun.hash_nom'},
          {set: this.encrypt(this.affaire.assure.prenom), to: 'devis.commun.hash_prenom'},
          {set: this.encrypt(this.affaire.assure.email), to: 'devis.commun.hash_email_sn'},
          {set: this.encrypt(this.affaire.assure.telephone), to: 'devis.commun.hash_phone_sn'},
          {set: this.affaire.assure.dateNaissance, to: 'devis.commun.dob'},
          {set: situationFamiliale.libelle, to: 'simulateur.obseques.sf'}
        ];
        this.trackingService.updateAndCommitTag(arraySet, 'simulateur_obseques_e3');
        this.sendMailTracking(caisse);
        break;
      case 4:
        arraySet = [
          {set: 'Mon assurance::Simulateur Obseques::Etape 3::Confirmation Envoi Devis', to: 'contenu.fil_ariane'},
          {set: 'Confirmation', to: 'outils_an.nom_etape'},
          {set: '4', to: 'outils_an.numero_etape'},
          {set: this.affaire.assure.tarif.montantCapitalDeces, to: 'simulateur.obseques.montant_capital'},
          {
            set: this.affaire.assure.tarif.typePrime == 'fixe' ? 'temporaire' : this.affaire.assure.tarif.typePrime,
            to: 'simulateur.obseques.type_cotisation'
          },
          {set: periodicite.libelle, to: 'simulateur.obseques.periodicité'},
          {set: this.affaire.assure.tarif.duree, to: 'simulateur.obseques.duree_cotisation'},
          {set: this.affaire.totalAc, to: 'simulateur.obseques.tarif_mensuel'}
        ];

        if (this.affaire.isTwoAssure) {
          arraySet.push({
            set: this.affaire.secondAssure.tarif.montantCapitalDeces,
            to: 'simulateur.obseques.montant_capital_conjoint'
          });
          arraySet.push({
            set: this.affaire.secondAssure.tarif.typePrime == 'fixe' ? 'temporaire' : this.affaire.secondAssure.tarif.typePrime,
            to: 'simulateur.obseques.type_cotisation_conjoint'
          });
          arraySet.push({
            set: this.affaire.secondAssure.tarif.duree,
            to: 'simulateur.obseques.duree_cotisation_conjoint'
          });
        }

        this.trackingService.updateAndCommitTag(arraySet, 'simulateur_obseques_confirmation');
        break;
      default:
        break;
    }
  }

  private refreshLibelle(assure) {
    // mise à jour du libellé pour situation familiale
    const situationFamiliale = _.find(this.situationFamiliales, {code: this.affaire[assure].situationFamiliale});
    this.affaire[assure].situationFamilialeLibelle = situationFamiliale.libelle;
    // mise à jour du libellé pour la périodicité
    const periodicite = _.find(this.periodicites, {code: this.affaire[assure].tarif.periodicite});
    this.affaire[assure].tarif.periodiciteLibelle = periodicite.libelle;
  }

  sendMail() {
    this.affaire.statut = 'Devis Envoyé';
    this.myStepper.next();
    if (this.urlFrom == 'GCA') {
      this.api.save(this.affaire).subscribe((res: any) => {
        this.api.getType(this.affaire.ID_USER, 'sendMail/' + this.urlFrom)
          .subscribe((res: any) => {
            // this.openSnackBar("Votre devis vient de vous être envoyé.");
            this.myStepper.next();
            this.tracking(4);
          }, err => {
            this.openSnackBar('Une erreur est survenu lors de l\'envoi de votre devis, veuillez réessayer.');
            console.log(err);
          });
      }, err => {
        this.openSnackBar('Une erreur est survenu lors de l\'envoi de votre devis, veuillez réessayer.');
        console.log(err);
      });
    } else {
      this.api.save(this.affaire).subscribe((res: any) => {
        this.api.getType(this.affaire.ID_USER, 'sendMail/' + environment.nameApp)
          .subscribe((res: any) => {
            // this.openSnackBar("Votre devis vient de vous être envoyé.");
            this.myStepper.next();
            this.tracking(4);
          }, err => {
            this.openSnackBar('Une erreur est survenu lors de l\'envoi de votre devis, veuillez réessayer.');
            console.log(err);
          });
      }, err => {
        this.openSnackBar('Une erreur est survenu lors de l\'envoi de votre devis, veuillez réessayer.');
        console.log(err);
      });
    }
  }

  sendMailTracking(caisse) {
    if (caisse.CR_TRACKING == 'GOC') {
      this.refreshLibelle('assure');
      this.api.save(this.affaire).subscribe((res: any) => {
        this.api.getType(this.affaire.ID_USER, 'sendMailTracking')
          .subscribe((res: any) => {
            return;
          }, err => {
            console.log(err);
          });
      }, err => {
      });
    }
  }

  private updateConjoint() {
    return (this.assureFormGroup.value.situationFamiliale === '1' ||
      this.assureFormGroup.value.situationFamiliale === '5' ||
      this.assureFormGroup.value.situationFamiliale === '6');
  }

  changeSituationFamiliale() {
    this.affaire.hasConjoint = this.updateConjoint();
    if ((environment.nameApp == AppNameEnum.GROUPAMA || environment.nameApp == AppNameEnum.GAN_PAT) && !this.affaire.hasConjoint) {
      this.isPromotionDisplay = false;
      this.affaire.isTwoAssure = false;
    } else {
      this.isPromotionDisplay = true;
    }
  }

  openSnackBar(msg) {
    this.snackBar.open(msg, '', {
      duration: 15000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['display-snackbar']
    });
  }

  public recalc(event?) {
    if (event != null) {
      if (event.type == 'assure') {
        this.assureFormGroup = event.assure;
        this.affaire.assure = _.cloneDeep(this.assureFormGroup.value);
      } else {
        this.secondAssureFormGroup = event.assure;
        this.affaire.secondAssure = _.cloneDeep(this.secondAssureFormGroup.value);
      }
    } else {
      this.affaire.assure = _.cloneDeep(this.assureFormGroup.value);
      this.affaire.secondAssure = _.cloneDeep(this.secondAssureFormGroup.value);
    }
    const promises = [];
    if (!this.affaire.isTwoAssure) {
      promises.push(Helper.recalc(this.affaire.assure, this.baremes, null, this.api));
      promises.push(Q.when());

    } else {
      // calcul sur deux assurés et en fonction de la situation maritale on affecte une réduction différente
      if (this.affaire.hasConjoint) {
        // si second assuré est de type conjoint alors
        promises.push(Helper.recalc(this.affaire.assure, this.baremes, null, this.api));
        promises.push(Helper.recalc(this.affaire.secondAssure, this.baremes,
          APP_CONSTANTS.REDUCTION.CONJOINT, this.api));
        promises.push(Q.when());

      } else {
        // si le second assuré n'est pas de type conjoint alors
        promises.push(Helper.recalc(this.affaire.assure, this.baremes, APP_CONSTANTS.REDUCTION.SECOND_ASSURE, this.api));
        promises.push(Helper.recalc(this.affaire.secondAssure, this.baremes,
          APP_CONSTANTS.REDUCTION.SECOND_ASSURE, this.api));
        promises.push(Q.when());
      }
    }

    this.updateTotalAC(promises).then((result: any) => {
        this.refreshLibelle('assure');
        this.affaire.secondAssure.tarif.periodicite = this.affaire.assure.tarif.periodicite;
        this.refreshLibelle('secondAssure');
        this.affaire.dateMiseAJour = moment(new Date()).format('DD/MM/YYYY');
        console.log(this.assureFormGroup.value.dateNaissance);
        this.affaire.assure.dateNaissance = moment(this.assureFormGroup.value.dateNaissance, 'DDMMYYYY').format('DD/MM/YYYY'),
          console.log(this.affaire.assure.dateNaissance);
        this.affaire.secondAssure.dateNaissance = moment(this.secondAssureFormGroup.value.dateNaissance, 'DDMMYYYY').format('DD/MM/YYYY'),
          this.affaire.totalAc = result;
        this.api.save(this.affaire)
          .subscribe((res: any) => {

          }, err => {
            console.log(err);
          });
      }
    );
  }

  /** ------------------------------------------------------------------------------------------
   *  recalc all
   ------------------------------------------------------------------------------------------ */
  updateTotalAC(promises): Promise<any> {
    const promise = new Promise<any>((resolve, reject) => {

      Q.all(promises)
        .then((result: Array<any>) => {
          const _self = this;
          if (result[0] != false) { _self.assureFormGroup.value.tarif = result[0].tarif; }

          // conjoint -> calc 10% deja fait, on ajuste sur l'assure
          let totalACConjoint = 0;
          if (result[1]) {
            const totalConjoint = result[1].totalAC;
            const diviseurAssure = Helper.getPeriodiciteDiviseur(_self.assureFormGroup.value.tarif.periodicite);
            const diviseurConjoint = Helper.getPeriodiciteDiviseur(_self.secondAssureFormGroup.value.tarif.periodicite);
            totalACConjoint = totalConjoint / (diviseurAssure / diviseurConjoint);
          }

          const totalACAssure = result[0].totalAC + totalACConjoint;
          const periodiciteAffiche = Helper.getPeriodiciteLibelle(_self.assureFormGroup.value.tarif.periodicite);

          _self.totalACAffiche = new DecimalPipe(APP_CONSTANTS.LOCALE).transform(totalACAssure, '1.2-2') + ' €';
          _self.periodicite = periodiciteAffiche;
          const totalAC = Number.parseFloat(totalACAssure).toFixed(2);
          resolve(totalAC);
        })
        .catch((error) => {
          console.log('error on recalc Simulateur..');
        });

    });
    return promise;
  }

  isDisabled() {
    if (!this.assureFormGroup.valid) { return true; }
    if (this.assureFormGroup.valid && this.isSecondControl && !this.secondAssureFormGroup.valid) { return true; }
    return false;
  }

  str2ab(str) {
    const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  encrypt(msg) {
    // return msg;
    return CryptoJS.AES.encrypt(msg, key, {
      keySize: 16,
      iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
  }

  returnHome() {
    if (this.appName == this.enumApp.GROUPAMA) {
      if (this.urlFrom == 'GCA') {
        window.location.href = 'https://simulateur-obseques.groupama.fr/GCA';
      } else {
        window.location.href = 'https://simulateur-obseques.groupama.fr/';
      }
    } else if (this.appName == this.enumApp.GAN_PAT) {
      window.location.href = 'https://simulateur-obseques.ganpatrimoine.fr/';
    }
  }

}
