import {FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import {Assure} from './assure';
import {Tarif} from './tarif';
import * as _ from 'lodash';
import {APP_CONSTANTS} from '../app.constants';
import {CustomValidators} from '../commons/validators/CustomValidators';
import {RestApiService} from '../../services/rest-api.service';
import {map} from 'rxjs/operators';

import * as moment from 'moment';

export class Helper {


  /**
   * Récupération de la valeur des date pour les datepicker
   */
  private static getValueDate(value: any) {
    let date = null;
    date = moment(value).toISOString();
    return date;
  }

  static initForm(fb: FormBuilder, assure: Assure, dateMin, dateMax): FormGroup {
    return fb.group({
      civilite: [assure.civilite, Validators.required],
      nom: [assure.nom, Validators.required],
      prenom: [assure.prenom, Validators.required],
      telephone: [assure.telephone,
        [Validators.required, Validators.maxLength(10), Validators.minLength(10)]],
      email: [assure.email,
        [Validators.required, Validators.email]],
      dateNaissance: [this.getValueDate(assure.dateNaissance), [Validators.required, CustomValidators.dateValidator(dateMin, dateMax)]],
      codepostal: [assure.codepostal,
        [Validators.required, Validators.maxLength(5), Validators.minLength(5)]],
      situationFamiliale: [assure.situationFamiliale, Validators.required],
      tarif: this.initFormTarif(fb, assure.tarif)
    });
  }

  static initFormTarif(fb: FormBuilder, tarif: Tarif): FormGroup {
    return fb.group({
      montantCapitalDeces: [tarif.montantCapitalDeces,
        [Validators.required, Validators.min(850), Validators.max(15000)]],
      periodicite: [tarif.periodicite, Validators.required],
      duree: [tarif.duree, Validators.required],
      typePrime: [tarif.typePrime, Validators.required],
      gainAvecRemise: [tarif.gainAvecRemise],
      totalDC: [tarif.totalDC],
      totalAssistance: [tarif.totalAssistance],
      cotisation: [tarif.cotisation],
    });
  }

  static getPeriodicite(periodicite: string): any {
    let libelle = '';
    let diviseur = 12;
    switch (periodicite) {
      case APP_CONSTANTS.PERIODICITE.ANNUELLE.code:
        libelle = APP_CONSTANTS.PERIODICITE.ANNUELLE.libelle;
        diviseur = 1;
        break;
      case APP_CONSTANTS.PERIODICITE.SEMESTRIELLE.code:
        libelle = APP_CONSTANTS.PERIODICITE.SEMESTRIELLE.libelle;
        diviseur = 2;
        break;
      case APP_CONSTANTS.PERIODICITE.TRIMESTRIELLE.code:
        diviseur = 4;
        libelle = APP_CONSTANTS.PERIODICITE.TRIMESTRIELLE.libelle;
        break;
      case APP_CONSTANTS.PERIODICITE.MENSUELLE.code:
        libelle = APP_CONSTANTS.PERIODICITE.MENSUELLE.libelle;
        diviseur = 12;
        break;
    }
    return {
      libelle,
      diviseur
    };
  }

  /** -----------------------------------------------------------------------------------------------------
   *  calcul tarification
   * @param defaultDuree
   ----------------------------------------------------------------------------------------------------- */
   static recalc(assure: Assure, baremes: any, reduction: number, api: any): Promise<any> {

    const promise = new Promise<any>(async (resolve, reject) => {
      if (assure.tarif.duree === 0 || !assure.dateNaissance) {
        return;
      }
      const dataForTarif = this.generateDataForTarif(assure, reduction);

      api.post(dataForTarif)
        .subscribe((response) => {
          const tarification = response[0];
          resolve(this.getTotal(response, assure.tarif.periodicite));
        });

    });

    return promise;

  }

  private static generateDataForTarif(assure: Assure, reduction: number) {
    const periodicite = _.find(APP_CONSTANTS.PERIODICITE, {code: assure.tarif.periodicite});

    const obj = {
      opportunity: 'SIMU_OBS',
      produit: '1VIENTIE',
      garanties: [
        {
          key: '02GOP',
          complements: [
            {
              key: 'MONTANT',
              value: assure.tarif.montantCapitalDeces
            }
          ]
        },
        {
          key: '05ASS',
          complements: []
        }
      ],
      complements: [
        {
          key: 'DATE_EFFET',
          value: moment().format('DD/MM/YYYY')
        },
        {
          key: 'DATE_NAISSANCE',
          value: moment(assure.dateNaissance, 'DDMMYYYY').format('DD/MM/YYYY')
        },
        {
          key: 'DUREE_COTISATION',
          value: assure.tarif.typePrime === 'viagere' ? 'viagère' : assure.tarif.duree.toString()
        },
        {
          key: 'PERIODICITE',
          value: periodicite.title.toLowerCase()
        },
      ],
      version: 'mars-2023'
    };

    switch (reduction) {
      case 0.9:
        obj.complements.push(
          {
            key: 'AVANTAGES_COMMERCIAUX',
            value: 'ABATTEMENT_CONJOINT'
          }
        );
        break;
      case 0.95:
        obj.complements.push(
          {
            key: 'AVANTAGES_COMMERCIAUX',
            value: 'SECON_ASSURE'
          }
        );
        break;
      default:
        break;
    }
    return obj;
  }

  static getTotal(tarifAssure, periodiciteCode): any {
    const periodicite = this.getPeriodicite(periodiciteCode);
    const totalAC = tarifAssure[0].tarifications[0].primeEmiseTTC + tarifAssure[1].tarifications[0].primeEmiseTTC;
    return ({
      tarif: tarifAssure,
      totalAC,
      periodicite,
      totalDCAvecRemise: tarifAssure.gainAvecRemise

    });
  }

  /** -----------------------------------------------------------------------------------------------------
   *  MAJ de l'age
   ------------------------------------------------------------------------------------------------------ */
  static age(birthday: any) {
    console.log(moment.locale());
    const millesimeForToday = moment().year();
    let millesimeForBirth: any;
    if (typeof birthday == 'string') {
      millesimeForBirth = parseInt(birthday.substring(birthday.length - 4, birthday.length));
    } else {
      millesimeForBirth = moment(birthday).year();
    }
    const age = millesimeForToday - millesimeForBirth;
    return age;
  }

  static getPeriodiciteDiviseur(periodicite): number {
    let diviseur = 12;
    switch (periodicite) {
      case '1' :
        diviseur = 1;
        break;
      case '2' :
        diviseur = 2;
        break;
      case '3' :
        diviseur = 4;
        break;
      case '4' :
        diviseur = 12;
        break;
    }
    return diviseur;
  }

  static getPeriodiciteLibelle(periodicite): string {
    let libelle = '';
    switch (periodicite) {
      case '1' :
        libelle = '/an';
        break;
      case '2' :
        libelle = '/semestre';
        break;
      case '3' :
        libelle = '/trimestre';
        break;
      case '4' :
        libelle = '/mois';
        break;
    }
    return libelle;
  }


}
