import { Tarif } from './tarif';

export class Assure {

    civilite: string = "";
    dateNaissance:string = "";
    nom: string = "";
    nomNaissance: string = "";
    prenom: string = "";
    regimeMatrimonial: string = "";
    situationFamiliale: string = "";
    email:string = "";
    telephone: string = "";
    codepostal: string = "";
    tarif: Tarif



    public constructor() {
        this.situationFamiliale = "2";
        this.tarif = new Tarif();
    }


}
