import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SimulateurRoutingModule } from './simulateur-routing.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormulaireComponent } from '../components/formulaire/formulaire.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { PromotionComponent } from '../components/promotion/promotion.component';
import { CotisationComponent } from '../components/cotisation/cotisation.component';
import { Ng5SliderModule } from 'ng5-slider';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';

import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [FormulaireComponent, PromotionComponent, CotisationComponent],
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SimulateurRoutingModule,
    Ng5SliderModule,
    NgxMaskModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {
        provide:  MAT_DATE_FORMATS,
        useValue: {
            parse:   {
                dateInput: ['DD/MM/YYYY', 'DD/MM/YYYY'],
            },
            display: {
                dateInput:          'DD/MM/YYYY',
                monthYearLabel:     'MMM YYYY',
                dateA11yLabel:      'DD/MM/YYYY',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        },
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
  ],
  
  exports: [
    FormulaireComponent,
    PromotionComponent,
    CotisationComponent,
    MaterialModule,
    Ng5SliderModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
})
export class SimulateurModule { }
