import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderLayoutComponent } from './header-layout/header-layout.component';
import { CanLayout } from './canlayout';
import { AppComponent } from './app.component';


let routes: Routes = [
  {
    path: '',
        loadChildren: () => import('./simulateur/simulateur.module').then(m => m.SimulateurModule)
  // }, 
  // {
  //   path: 'GCA',
  //       loadChildren: () => import('./simulateur/simulateur.module').then(m => m.SimulateurModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {



 }
