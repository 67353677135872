import {Injectable } from '@angular/core';
import * as _ from "lodash";


export interface Config {
  permanentKey?: Array<string>;
  logDebug?: boolean;
}

export interface Tag {
  set?: string;
  add?: string;
  to: string;
}

export interface EarlyTag {
  tags: Array<Tag>;
  tagEvent: string;
}

@Injectable()
export class TrackingLibComponent {

  constructor() { }


  // --------------- PUBLIC --------------------
  TAG_PERMANENT_KEYS: Array<string> | undefined;
  LOG_DEBUG: boolean | undefined;
  earlyTags: Array<EarlyTag> = [];

  /**
   * Initialise le DataLayer avec les infos permanentes
   * @param initTags
   * @param config
   */
  public initTagging = (initTags: Record<string, unknown>, config?: Config): void => {
    (<any>window)["wa_gfr"] = initTags;
    this._setConfiguration(config);
    if(this.isTaggingReady()){
      this._commitEarlyTag();
    }
  };


  /**
   * Indique si le tagging est pret a etre utilisé
   * @returns {boolean}
   */
  public isTaggingReady = (): boolean => {
    // TODO (promesse?)
    return (
        (<any>window)._satellite &&
        (<any>window)._satellite.track &&
        (<any>window)._satellite.track.toString().length > 12 &&
        typeof this.TAG_PERMANENT_KEYS != "undefined"
    );
  };


  /**
   * modifie le datalayer et commit le tag
   * @param tags
   * @param tagEvent
   */
  public updateAndCommitTag = (tags: Array<Tag>, tagEvent: string): void => {
    if (this.isTaggingReady()) {
      this._commitEarlyTag();
      this._updateAndCommit(tags, tagEvent);
    } else {
      console.warn("tagging not ready !!");
      this.earlyTags.push({ tags, tagEvent });
    }
  };

  // --------------- PRIVATE --------------------
  private _updateAndCommit(tags: Array<Tag>, tagEvent: string) {
    if (this.LOG_DEBUG) {
      console.log('update : ', tags);
    }
    this._updateDataLayer(tags);
    if (tagEvent !== 'noTrack') {
      this._commitTag(tagEvent);
    }
  }

  private _commitEarlyTag(): void {
    if (this.earlyTags.length > 0) {
      this.earlyTags.forEach((objectTag) => this._updateAndCommit(objectTag.tags, objectTag.tagEvent));
      this.earlyTags = [];
    }
  }
  /**
   * modifie le datalayer avec X demande(s)
   */
  private _updateDataLayer(tags: Array<Tag>) {
    tags.forEach((tag: Tag) => this._updateOneDL(tag));
  }

  /**
   * modifie le datalayer avec 1 demande
   */
  private _updateOneDL(tag: Tag) {
    const layer = (<any>window)['wa_gfr'];
    if (tag.add) {
      let value = _.get(layer, tag.to);
      if (typeof value === 'string') {
        value += tag.add;
        (<any>window)['wa_gfr'] = _.set(layer, tag.to, value);
      } else {
        console.error(`Impossible d'ajouter ${tag.add} au tag courant`);
      }
    } else if (tag.set) {
      (<any>window)['wa_gfr'] = _.set(layer, tag.to, tag.set);
    }
  }

  /**
   * envoie un tag avec les info du datalayer
   * @param tagEvent
   */
  private _commitTag(tagEvent: string) {
    if (this.LOG_DEBUG) {
      console.log('wa_gfr : ', (<any>window)['wa_gfr']);
    }
    (<any>window)._satellite.track(tagEvent);
    this._cleanLayer();
  }

  private _cleanLayer() {
    (<any>window)['wa_gfr'] = _.pickBy(
      (<any>window)['wa_gfr'],
      (value, key) => this.TAG_PERMANENT_KEYS && this.TAG_PERMANENT_KEYS.includes(key)
    );
  }

  private _setConfiguration(config?: Config): void {
    this.TAG_PERMANENT_KEYS = config && config.permanentKey ? config.permanentKey : ['contenu', 'CRM', 'contact'];
    this.LOG_DEBUG = !!config && !!config.logDebug;
  }

}
