<form [formGroup]="formG">


    <!-- <div [ngSwitch]="appName"> -->
    <!-- <div class="row" *ngSwitchCase="enumApp.GAN_PREV">
            <div class="col-md-6">
                <div class="icon" id="monsieur_{{type}}" (click)="selectCivilite('monsieur')">
                    <input type="radio" formControlName="civilite" id="male" class="input-hidden" />
                    <div for="male">
                        <i class="fa fa-male"></i>
                    </div>
                    <h6 class="text-center">Monsieur</h6>

                </div>
            </div>

            <div class="col-md-6">
                <div class="icon" id="madame_{{type}}" (click)="selectCivilite('madame')">
                    <input type="radio" formControlName="civilite" id="female" class="input-hidden" />
                    <div for="female">
                        <i class="fa fa-female"></i>
                    </div>
                    <h6 class="text-center">Madame</h6>
                </div>
            </div>
        </div> -->

    <div class="row">
        <div class="col-md-6">
            <div style="display:flex;">
                <button mat-flat-button type="button" class="button-select"
                    [ngClass]="{error: formG.controls.civilite.errors?.required && isControl, pristine: formG.value.civilite != 'madame'}"
                    [color]="formG.value.civilite == 'madame' ? 'primary' : 'secondary'"
                    (click)="selectCivilite('madame')">Madame</button>
                <button mat-flat-button type="button" class="button-select"
                    [ngClass]="{error: formG.controls.civilite.errors?.required && isControl, pristine: formG.value.civilite != 'monsieur'}"
                    [color]="formG.value.civilite == 'monsieur' ? 'primary' : 'basic'"
                    (click)="selectCivilite('monsieur')" style="margin-left: 16px;">Monsieur</button>
            </div>
            <mat-error style="font-size: 75%;" *ngIf="formG.controls.civilite.invalid && isControl">Vous devez saisir
                votre civilité</mat-error>
        </div>
    </div>

    <br />
    <!-- </div> -->

    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="field">
                <mat-label>Nom</mat-label>
                <input matInput placeholder="Nom de famille" formControlName="nom" required>
                <mat-error *ngIf="formG.controls.nom.invalid && isControl">Vous devez saisir votre nom</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="field">
                <mat-label>Prénom</mat-label>
                <input matInput placeholder="Prénom" formControlName="prenom" required>
                <mat-error *ngIf="formG.controls.prenom.invalid && isControl">Vous devez saisir votre prénom</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="field">
                <mat-label>Téléphone</mat-label>
                <input matInput placeholder="Téléphone" formControlName="telephone" required mask="00 00 00 00 00">
                <mat-error *ngIf="formG.controls.telephone.invalid && isControl">Vous devez saisir votre numéro de
                    téléphone</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="field">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" formControlName="email" required type="email;;;">
                <mat-error *ngIf="formG.controls.email.invalid && isControl">Vous devez saisir votre email</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6" [style.marginBottom.px]="formG.controls.dateNaissance.errors?.dateValidator ? 20: 0">
            <!-- <mat-form-field appearance="outline" class="field">
                <mat-label>Date de naissance</mat-label>
                <input matInput placeholder="JJ/MM/AAAA" [matDatepicker]="picker" formControlName="dateNaissance" [max]="startDate"
                    required (change)="dateChange($event)">
                <mat-hint>Pour souscrire, vous devez avoir entre 40 et 85 ans</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker startView="multi-year" [startAt]="startDate" disabled="false"></mat-datepicker>
                <mat-error *ngIf="formG.controls.dateNaissance.errors?.required && isControl">Vous devez saisir votre date de naissance</mat-error>
                <mat-error *ngIf="!formG.controls.dateNaissance.errors?.required && formG.controls.dateNaissance.errors?.dateValidator">Pour souscrire, vous devez avoir entre 40 et 85 ans</mat-error>
            </mat-form-field> -->

            <mat-form-field appearance="outline" class="field">
                <mat-label>Date de naissance</mat-label>
                <!-- <input matInput placeholder="JJ/MM/AAAA" [matDatepicker]="picker" [max]="startDate"
                    required style="display: none;" (dateChange)="dateChange($event)"> -->
                <input matInput [mask]="'00/00/0000'" placeholder="JJ/MM/AAAA" formControlName="dateNaissance">
                <!-- <mat-datepicker #picker startView="multi-year" [startAt]="startDate" disabled="false"></mat-datepicker> -->
                <mat-hint *ngIf="formG.controls.dateNaissance.errors?.dateValidator">Pour souscrire, vous devez avoir
                    entre 40 et 85 ans</mat-hint>
                <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
                <mat-error *ngIf="formG.controls.dateNaissance.errors?.required && isControl">Vous devez saisir votre
                    date de naissance</mat-error>
                <mat-error
                    *ngIf="!formG.controls.dateNaissance.errors?.required && formG.controls.dateNaissance.errors?.dateValidator">
                    Pour souscrire, vous devez avoir entre 40 et 85 ans</mat-error>
            </mat-form-field>
        </div>
    </div>


    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="field">
                <mat-label>Code postal</mat-label>
                <input #codePostal matInput placeholder="" formControlName="codepostal" required pattern="[0-9]{5}"
                    maxlength="5">
                <!-- <mat-hint align="end">{{codePostal.value?.length || 0}}/5</mat-hint> -->
                <mat-error *ngIf="formG.controls.codepostal.invalid && isControl">Vous devez saisir un code postal
                    valide</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="!formG.controls.telephone.invalid">
        <div class="definition">
            <span>
                Afin d’échanger avec vous sur ce devis, vous êtes susceptible d’être recontacté
            </span><br>
            <span>par un de nos conseillers par téléphone et/ou par email.</span>
            <br>
        </div>

    </div>
</form>